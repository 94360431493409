import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { AddCelbrityVoice, CelbrityVoiceUpdate, CelbrityVoiceview } from "../../Auth/Api";
import { toast } from "react-toastify";
import Fancybox from '../../Component/FancyBox';


const CelebrityVoiceAdd = () => {
    const Redirect = useNavigate();
    const [loading, Setloading] = useState(false);
    const [validate, setValidate] = useState(false);
    const [Data, SetData] = useState({
        name: "",
        thumb_image_url: "",
        premium_status: "",
        reward_status: "",
        status: 1,
        child_image: ""
    });
    const params = useParams()

    const GetViewData = async () => {
        if (params.id) {
            const Result = await CelbrityVoiceview(params.id)
            if (Result.data.Status === true) {
                SetData(Result.data.Data)
                Setloading(false)
            }
            else {
                SetData([])
                Setloading(false)
            }
        } else {
            SetData({
                name: "",
                thumb_image_url: "",
                premium_status: "",
                reward_status: "",
                status: "",
                child_image: ""
            })
        }
    }

    const Save = async () => {
        if (Data.name === "" || Data.thumb_image_url === "" || Data.child_image === "") {
            setValidate(true);
        } else {
            setValidate(false);
            Setloading(true);
            if (params.id) {
                const Result = await CelbrityVoiceUpdate(Data, params.id);
                if (Result?.data?.Status === true) {
                    toast.success("Voice Update Successfully...");
                    Setloading(false);
                    Redirect(`/Celebrity-Voice/View/${params.id}`);
                } else {
                    toast.error(Result?.data?.Response_Message);
                }
            } else {
                const Result = await AddCelbrityVoice(Data);
                if (Result?.data?.Status === true) {
                    toast.success("Voice Saved Successfully...");
                    Setloading(false);
                    Redirect("/Celebrity-Voice");
                } else {
                    Setloading(false);
                    toast.error(Result?.data?.Response_Message);
                }
            }
        }
    };

    useEffect(() => {
        if (params.id) {
            GetViewData()
        }
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Celebrity Voice {params.id ? "Edit" : "Add"}</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item>
                        <Link to="/Home">
                            <i className="bx bx-home-alt me-2 fs-5"></i> Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/Celebrity-Voice">Celebrity Voice</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Celebrity Voice {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form method="post" noValidate validated={validate}>
                    <Card className="mb-4">
                        <Card.Body>
                            {loading === true ? (
                                <div className="loader table-loader"></div>
                            ) : (
                                <></>
                            )}
                            <Row>
                                <Col md={6}>
                                    <Form.Label>Celebrity Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        className="my-2"
                                        value={Data.name}
                                        onChange={(e) => {
                                            SetData({
                                                ...Data,
                                                name: e.target.value,
                                            });
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Your Celebrity Name
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={6}>
                                    <Form.Label htmlFor="mainimage">Thumb Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="thumb_image_url"
                                            onChange={(e) => {
                                                SetData({
                                                    ...Data,
                                                    thumb_image_url: e.target.files[0],
                                                });
                                            }}
                                            required />
                                        {
                                            params.id &&
                                            Data.thumb_image_url !== "" &&
                                            <Fancybox>
                                                <a href={Data.thumb_image_url} data-fancybox="gallery">
                                                    <img src={Data.thumb_image_url} alt="" className="hv-40 rounded-3" />
                                                </a>
                                            </Fancybox>
                                        }

                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Thumb Image
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="mainimage">Child Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="child_image"
                                            onChange={(e) => {
                                                SetData({
                                                    ...Data,
                                                    child_image: e.target.files[0],
                                                });
                                            }}
                                            required />
                                        {
                                            params.id &&
                                            Data.child_image !== "" &&
                                            <Fancybox>
                                                <a href={Data.child_image} data-fancybox="gallery">
                                                    <img src={Data.child_image} alt="" className="hv-40 rounded-3" />
                                                </a>
                                            </Fancybox>
                                        }

                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Child Image
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={2}>
                                    <Form.Label>Premium Status</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        name="premium_status"
                                        className="my-2 yes-no"
                                        checked={Data.premium_status === 1}
                                        onChange={(e) => {
                                            SetData({
                                                ...Data,
                                                premium_status: e.target.checked ? 1 : 0,
                                                reward_status: e.target.checked ? 0 : Data.reward_status,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Form.Label>Reward Status</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        name="reward_status"
                                        className="my-2 yes-no"
                                        checked={Data.reward_status === 1}
                                        onChange={(e) => {
                                            SetData({
                                                ...Data,
                                                reward_status: e.target.checked ? 1 : 0,
                                                premium_status: e.target.checked ? 0 : Data.premium_status,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        name="status"
                                        className="my-2"
                                        checked={Data.status === 1 ? true : false}
                                        onChange={(e) => {
                                            SetData({
                                                ...Data,
                                                status: e.target.checked ? 1 : 0,
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3" onClick={Save}>
                                Save
                            </Button>
                            <Link to={params.id ? `/Celebrity-Voice/View/${params.id}` : "/Celebrity-Voice"}>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    );
};

export default CelebrityVoiceAdd;
