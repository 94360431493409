import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Table, Form } from "react-bootstrap";
import Layout from "../../layout/Layout";
import Pagination from "rc-pagination";
import Fancybox from "../../Component/FancyBox";
import { CelbrityVoiceUpdatePostion, CelbrityVoiceUpdateStatus, CelebrityVoiceDelete, GetAllCelbrityVoice } from "../../Auth/Api";
import $ from "jquery";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Swal from "sweetalert2";
import { toast } from "react-toastify";
const CelebrityVoice = () => {
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);
    const [loading, Setloading] = useState(true);
    const [Data, SetData] = useState([]);
    const [term, setTerm] = useState("")
    const [voiceData, setVoiceData] = useState([])
    const [CopyToClipboardData, setCopyToClipboardData] = useState([])
    const [isChildOrAdult, setIsChildOrAdult] = useState('-1')
    const GetData = async () => {
        Setloading(true)
        const Result = await GetAllCelbrityVoice(term);
        if (Result?.data?.Status === true) {
            SetData(Result?.data?.Data);
            const _result = Result.data.Data;
            const _filterData = _result.slice((current - 1) * size, current * size);
            // if (isChildOrAdult == 1 || isChildOrAdult == 0) {
            // }
            setVoiceData(_filterData);
            Setloading(false);
        } else {
            SetData([]);
            Setloading(false);
        }
    };


    const handleOnDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) return;
        let items = Array.from(voiceData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        console.log("=items", items);
        let data = items.map(item => item._id)
        const pageInformation = {
            currentPage: current,  // Assuming you have a variable named 'current' for the current page
            sourceIndex: source.index,
            destinationIndex: destination.index
        };
        const updatePotion = await CelbrityVoiceUpdatePostion(data, pageInformation)
        if (updatePotion.data.Status === true) {
            toast.success("Position Update Successfully...");
            GetData();
        } else {
            toast.error(updatePotion.data.Response_Message);
        }
    };
    // const getData = (current, pageSize) => {
    //     return Data.slice((current - 1) * pageSize, current * pageSize);
    // };
    // console.log('getData >>>', getData)
    let count = 10;
    let swalCountdownInterval;
    const DeleteVoice = async (id) => {
        // console.log("dfdf")
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: "../../icon/alert.svg",
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr("disabled", true);
                    swalCountdownInterval = setInterval(function () {
                        count--;
                        if (count < 1) {
                            $(".counter").text(`OK`);
                            $(".swal2-confirm").attr("disabled", false);
                            clearInterval(swalCountdownInterval);
                        } else {
                            $(".counter").text(`OK (${count})`);
                        }
                    }, 1000);
                },
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const Result = await CelebrityVoiceDelete(id);
                    if (Result.data.Status === true) {
                        toast.success("Voice Delete Successfully...");
                        setCurrent(1)
                        GetData()
                        // Redirect(`/Category`)
                    } else {
                        toast.error(Result.data.Response_Message);
                    }
                } else {
                    count = 10;
                    clearInterval(swalCountdownInterval);
                }
            });
    };

    const ChangeStatus = async (e, id) => {
        const Result = await CelbrityVoiceUpdateStatus(id, e.target.name, (e.target.checked == true) ? 1 : 0)
        if (Result.data.Status === true) {
            toast.success("Status Update Successfully...")
            GetData()
        }
        else {
            toast.error(Result.data.Response_Message)
            GetData()
        }
    }
    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(Data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }
    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
        const _filterData = Data.slice((page - 1) * pageSize, page * pageSize);
        setVoiceData(_filterData);
    };
    useEffect(() => {
        GetData();
    }, [term, current, isChildOrAdult]);

    const jsonString = JSON.stringify({ "CelebrityVoice": CopyToClipboardData }, null, 2);
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Celebrity Voice</h3>
                <div className="page-heading-right">
                    <Form.Control
                        type="text"
                        placeholder="Search Name"
                        className="wv-200 my-1 ms-3"
                        onChange={e => setTerm(e.target.value)}
                        value={term}
                    />


                    <Link to="/Celebrity-Voice/Add" className="my-1 ms-3">
                        <Button variant="primary" value="create">
                            Add New
                        </Button>
                    </Link>
                </div>
            </div>
            <div className="page-content">
                <Card>
                    <Card.Body>
                        {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">
                                        No
                                    </th>
                                    <th width="35%">Celebrity Name</th>
                                    <th width="10%" className="text-center">
                                        Child Image
                                        <CopyToClipboard text={JSON.stringify(
                                            {
                                                "CelebrityVoice": Data?.map(item => {
                                                    console.log("first")
                                                    return {
                                                        name: item.name,
                                                        is_premium: item.premium_status == 1 ? true : false,
                                                        thumbnail: item.child_image || null,
                                                        is_rewarded: item.reward_status == 1 ? true : false,
                                                    };
                                                }),
                                            },
                                            null,
                                            2  // Indentation level (you can adjust this)
                                        )}
                                            onCopy={(isCopy, status) => {
                                                if (status == true) {
                                                    toast?.success("Child Data Copy To Clipboard");
                                                }
                                            }}
                                        >
                                            <i className="bx bx-copy btn btn-info p-1 ms-2 rounded-3"></i>
                                        </CopyToClipboard>
                                    </th>
                                    <th width="10%" className="text-center">
                                        Thumb Image
                                        <CopyToClipboard text={
                                            JSON.stringify({
                                                "CelebrityVoice": Data?.map(item => {
                                                    console.log("first")
                                                    return {
                                                        name: item.name,
                                                        is_premium: item.premium_status == 1 ? true : false,
                                                        thumbnail: item.thumb_image_url || null,
                                                        is_rewarded: item.reward_status == 1 ? true : false,
                                                    }
                                                })
                                            }, null, 2)
                                        } onCopy={(isCopy, status) => {
                                            console.log('status -->', status)
                                            if (status == true) {
                                                toast?.success("Adult Data Copy To Clipboard ")
                                            }
                                        }}>
                                            <i className="bx bx-copy btn btn-info p-1 ms-2 rounded-3"></i>
                                        </CopyToClipboard>
                                    </th>
                                    <th width="10%" className="text-center">
                                        Premium Status
                                    </th>
                                    <th width="10%" className="text-center">
                                        Reward Status
                                    </th>
                                    <th width="10%" className="text-center">
                                        Status
                                    </th>
                                    <th width="10%" className="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="stamps">
                                    {(provided) => (
                                        <tbody ref={provided.innerRef}
                                            {...provided.droppableProps}>
                                            {voiceData.length > 0 &&
                                                voiceData.map((val, index) => {
                                                    return (

                                                        <Draggable
                                                            key={index}
                                                            draggableId={index.toString()}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <tr
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        isDragging={snapshot.isDragging}
                                                                    >
                                                                        <td className="text-center"
                                                                        >
                                                                            {current === 1
                                                                                ? index + 1
                                                                                : current * size + index + 1 - size}</td>
                                                                        <td>{val.name}</td>
                                                                        <td className="text-center">
                                                                            {
                                                                                val.child_image ?
                                                                                    <Fancybox>
                                                                                        <a href={val.child_image} data-fancybox="gallery">
                                                                                            <img
                                                                                                src={val.child_image}
                                                                                                alt=""
                                                                                                className="hv-40 rounded-3"
                                                                                            />
                                                                                        </a>
                                                                                    </Fancybox>
                                                                                    : "-"
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                val.thumb_image_url ?
                                                                                    <Fancybox>
                                                                                        <a href={val.thumb_image_url} data-fancybox="gallery">
                                                                                            <img
                                                                                                src={val.thumb_image_url}
                                                                                                alt=""
                                                                                                className="hv-40 rounded-3"
                                                                                            />
                                                                                        </a>
                                                                                    </Fancybox>
                                                                                    : "-"
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <Form.Check
                                                                                type="switch"
                                                                                name="premium_status"
                                                                                className="yes-no"
                                                                                onChange={(e) => {
                                                                                    ChangeStatus(e, val._id);
                                                                                }}
                                                                                checked={val.premium_status == 1 ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <Form.Check
                                                                                type="switch"
                                                                                name="reward_status"
                                                                                className="yes-no"
                                                                                onChange={(e) => {
                                                                                    ChangeStatus(e, val._id);
                                                                                }}
                                                                                checked={val.reward_status == 1 ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <Form.Check
                                                                                type="switch"
                                                                                name="status"
                                                                                onChange={(e) => {
                                                                                    ChangeStatus(e, val._id);
                                                                                }}
                                                                                checked={val.status == 1 ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <Link to={`/Celebrity-Voice/View/${val._id}`}>
                                                                                <Button
                                                                                    variant="outline-warning"
                                                                                    size="sm"
                                                                                    className="me-2 btn-icon"
                                                                                >
                                                                                    <i className="bx bx-show"></i>
                                                                                </Button>
                                                                            </Link>
                                                                            <Button
                                                                                onClick={() => DeleteVoice(val._id)}
                                                                                variant="outline-danger"
                                                                                size="sm"
                                                                                className="btn-icon"
                                                                            >
                                                                                <i className="bx bx-trash-alt" ></i>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            }
                                                        </Draggable>
                                                    );
                                                })}
                                        </tbody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {
                                loading == false && Data.length == 0 ? <tr>
                                    <td colSpan="100%" class="p-0">
                                        <div class="no-found">
                                            <img src="../../../not-found/stamp.svg" />
                                            <p>No Found Celebrity Voice</p>
                                        </div>
                                    </td>
                                </tr> : ""
                            }
                        </Table>
                        {(Data.length > size) ?
                            <div className="pagination-custom">
                                <Pagination
                                    className="pagination-data"
                                    onChange={PaginationChange}
                                    current={current}
                                    total={Data.length}
                                    pageSize={10}
                                    showSizeChanger={false}
                                    itemRender={PrevNextArrow}
                                    onShowSizeChange={PerPageChange}
                                    showTitle={false}
                                />
                            </div> : ""}
                    </Card.Body>
                </Card>
            </div>
        </Layout >
    );
};

export default CelebrityVoice;
