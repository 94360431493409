import React, {useState, useEffect} from 'react';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import Fancybox from './../Component/FancyBox';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import NavigationMenu from '../WebComponent/NavigationMenu';
import ScrollToActive from '../WebComponent/ScrollToActive';
import Slider from 'react-slick';
import { useParallax } from "react-scroll-parallax";

const WelCome = () => {
    const currentYear = new Date().getFullYear();
    const [headerClass, setHeaderClass] = useState('scroll-off');
    const [showButton, setShowButton] = useState(false);
    const activeSection = ScrollToActive();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 10) {
                setHeaderClass('scroll-on');
            } else {
                setHeaderClass('scroll-off');
            }
        };

        const backScroll = () => {
            if (window.scrollY > 100) {
              setShowButton(true);
            } else {
              setShowButton(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', backScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', backScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    var settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 5,
        centerPadding:0,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1660,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    
    // const parallax = useParallax({
    //     translateX: [0, -30, "easeInQuint"],
    // });

    // const parallax2 = useParallax({
    //     translateY: [0, -50, "easeInQuint"],
    // });

    // const parallax3 = useParallax({
    //     translateX: [0, 30, "easeInQuint"],
    // });

    return (
        <div className={`w-gps ${headerClass}`}>
            <div className='w-header'>
                <Container>
                    <Navbar expand="lg" className="bg-body-tertiary">
                        <Navbar.Brand href="#">
                            <img src="/web/logo/logo.png" alt="AI Voice Changer: Funny Effect"/>
                            <span>AI Voice Changer</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <NavigationMenu activeSection={activeSection} />
                        </Navbar.Collapse>
                        <div className='nav-app-btn'>
                            <a className="app-store-btn btn-google ms-lg-3" target="_blank" href="https://play.google.com/store/apps/details?id=com.voice.changer.sound.effects.girl.call" role="button"></a>
                            <a className="app-store-btn btn-apple ms-lg-3" target="_blank" href="https://apps.apple.com/my/app/ai-voice-changer-sound-board/id1659583821" role="button"></a>
                        </div>
                    </Navbar>
                </Container>
            </div>
            <section className='section w-hero' id='home'>
                <Container  className='position-relative'>
                    <Row className='align-items-center'>
                        <Col lg={6} md={12}>
                            <div className='hero-content'>
                                <h1>AI Voice <span>Changer:</span><br/> Funny Effect</h1>
                                <div className='hero-btn'>
                                    <a className="app-store-btn btn-google me-2" target="_blank" href="https://play.google.com/store/apps/details?id=com.voice.changer.sound.effects.girl.call" role="button">
                                        <span className="app-store-btn-subtitle">Get It On</span>
                                        <span className="app-store-btn-title">Google Play</span>
                                    </a>
                                    <a className="app-store-btn btn-apple ms-2" target="_blank" href="https://apps.apple.com/my/app/ai-voice-changer-sound-board/id1659583821" role="button">
                                        <span className="app-store-btn-subtitle">Download on the</span>
                                        <span className="app-store-btn-title">App Store</span>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className='hero-media'>
                                <img src="web/hero/hero.png" className='img-fluid app-img' alt="AI Voice Changer App" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='hero-shape'>
                    <img src="web/hero/shape-1.svg" className='shape-1' alt="Leady Icon - AI Voice Changer" />
                    <img src="web/hero/shape-2.svg" className='shape-2' alt="Girl Funny Face" />
                    <img src="web/hero/shape-3.svg" className='shape-3' alt="Boy Funny Face" />
                    <img src="web/hero/shape-4.svg" className='shape-4' alt="Shape 4 icon" />
                    <img src="web/hero/shape-5.svg" className='shape-5' alt="Shape 5 icon" />
                    <img src="web/hero/shape-6.svg" className='shape-6' alt="Shape 6" />
                </div>
            </section>
            <section className='w-app-details'>
                <Container>
                    <Row>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>250K+</h3>
                                <p>Happy Users</p>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>500K+</h3>
                                <p>App Installed</p>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>20K+</h3>
                                <p>Download Per Days</p>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>1K+</h3>
                                <p>Positive Reviews</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section w-about pb-0' id='about'>
                <Container>
                    <h2 className='title'>About AI Voice-Changer App</h2>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className='about-media'>
                                <img src="web/about/about.png" className='img-fluid' alt="About AI Voice Changer App" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='about-content'>
                                <ul>
                                    <li>Transform your voice into that of a celebrity using AI voice changer, making your conversations extra exciting and entertaining.</li>
                                    <li>Create funny prank reels using funny voices and share them with friends and family to spread the fun and laugh.</li>
                                    <li>Trick your friends with funny sounds, adding an element of surprise and amusement to your interactions.</li>
                                    <li>Got a funny message in mind? Voice Changer helps you create it! Turn any text into hilarious speech with just a few taps and bring smiles to everyone's face.</li>
                                    <li>Record your voice or video with funny effects, bringing a touch of comedy to every recording and making memories even more enjoyable.</li>
                                    <li>Spread laughter with friends and family by saving & sharing your funny creations using the Voice-Changer App.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='about-shape'>
                    <img src="web/about/left.png" alt="Left Design" className='shape-1' />
                    <img src="web/about/right.png" alt="Triangle Design" className='shape-2' />
                </div>
            </section>
            <section className='section w-features' id='features'>
                <Container>
                    <h2 className='title'>Awesome Apps Features</h2>
                    <Row>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/1.png" className="img-fluid" alt="AI Voices Mimic Celebs!" title="AI Voices Mimic Celebs!"/>
                                <div className="features-card-body">
                                    <h3>AI Voices Mimic Celebs!</h3>
                                    <p>Change your voice to sound like your favorite celebrity using the AI voice feature.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/2.png" className="img-fluid" alt="Funny Prank Reels!" title="Funny Prank Reels!"/>
                                <div className="features-card-body">
                                    <h3>Funny Prank Reels!</h3>
                                    <p>Have fun listening to or creating funny prank reels using the app's sound effects and voice modification features.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/3.png" className="img-fluid" alt="Prank Funny Sounds" title="Prank Funny Sounds"/>
                                <div className="features-card-body">
                                    <h3>Prank Funny Sounds</h3>
                                    <p>Prank your friends and family with funny sound effects from AI Voice Changer: Funny Effect App’s library.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/4.png" className="img-fluid" alt="Text-to-Audio" title="Text-to-Audio"/>
                                <div className="features-card-body">
                                    <h3>Text-to-Audio</h3>
                                    <p>Convert text to speech using the app's text-to-audio feature. You can also modify the voice of the generated speech.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/5.png" className="img-fluid" alt="Record Voice and Video" title="Record Voice and Video"/>
                                <div className="features-card-body">
                                    <h3>Record Voice and Video</h3>
                                    <p>Record your voice or video and apply funny voice effects to it.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/6.png" className="img-fluid" alt="Save and Share" title="Save and Share"/>
                                <div className="features-card-body">
                                    <h3>Save and Share</h3>
                                    <p>Save your funny voice recordings and creations and share them with friends and family.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='features-shape'>
                    <img src="web/features/left.png" alt="Two Triangle Design" className='shape-1' />
                </div>
            </section>
            <section className='section w-how-works' id='howitworks'>
                <Container>
                    <h2 className='title'>How Voice Changer App Works</h2>
                    <Row className='align-items-center'>
                        <Col lg={4} className='order-lg-1 order-2'>
                            <div className='work-content'>
                                <h3>Watch Free Video Demo Presentation</h3>
                                <p>Get ready to laugh with AI Voice Changer! This app lets you sound like a celebrity, prank your friends with funny sounds, and create hilarious voiceovers. Record funny videos, and share the laughs. it's the perfect app for endless fun!</p>
                            </div>
                        </Col>
                        <Col lg={4} className='order-lg-2 order-1'>
                            <div className='work-media'>
                                <img src="web/hero/hero.png" className="img-fluid" alt="Watch Free Video Demo Presentation" title="Watch Free Video Demo Presentation"/>
                                <Fancybox>
                                    <a href="https://www.youtube.com/embed/jLpwD9U46bM" className='btn-play' data-fancybox="gallery">
                                        <div className="btn-outer-circle has-scale-animation"></div>
                                        <div className="btn-outer-circle has-scale-animation has-delay-short"></div>
                                        <div className="btn-icon">
                                            <i className='bx bx-play'></i>
                                        </div>
                                    </a>
                                </Fancybox>
                            </div>
                        </Col>
                        <Col lg={4} className='order-lg-3 order-3'>
                            <div className='work-list'>
                                <img src="web/how-it-work/1.svg" alt="Secure Your Data" />
                                <div className='work-list-content'>
                                    <h4>Secure Your Data</h4>
                                    <p>High-level encryption algorithms are implemented to secure saved data in the Voice Changers app.</p>
                                </div>
                            </div>
                            <div className='work-list'>
                                <img src="web/how-it-work/2.svg" alt="Fast Performance" />
                                <div className='work-list-content'>
                                    <h4>Fast Performance</h4>
                                    <p>Easy to load each and every feature of this application in a fraction of a second.</p>
                                </div>
                            </div>
                            <div className='work-list'>
                                <img src="web/how-it-work/3.svg" alt="Free Update" />
                                <div className='work-list-content'>
                                    <h4>Free Update</h4>
                                    <p>Update AI Voice Changer app regularly to experience the most desired, modern, and useful features.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section w-screen' id='screens'>
                <Container fluid>
                    <h2 className='title'>Awesome Interface</h2>
                    <Row>
                        <Col xs={12}>
                            <Slider {...settings}>
                                <div className='text-center'>
                                    <img src="web/screen/1.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/2.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/3.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/4.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/5.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/6.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/7.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section w-review' id='reviews'>
                <Container>
                    <h2 className='title'>What Our Customers Are Saying</h2>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className='review-media'>
                                <img src="/web/review/review.svg" className='img-fluid' alt="What Our Customers Are Saying" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Swiper
                                className="mySwiper"
                                navigation={true}
                                modules={[Navigation]}
                            >
                                <SwiperSlide>
                                    <div className='review-content'>
                                        <div className='review-star'>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star"></i>
                                        </div>
                                        <p>I can't believe how realistic the celebrity voices sound! This AI Voice Changer makes me feel like I'm talking like my favorite stars. It's so much fun and always gets a laugh from my friends!</p>
                                        <div className='review-user'>
                                            <img src="/web/review/user1.png" alt="Matt Muracio - AI Voice Changer App Reviewer" />
                                            <div className="review-user-details">
                                                <h4>Matt mauricio</h4>
                                                <span>January 15, 2021</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='review-content'>
                                        <div className='review-star'>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star"></i>
                                        </div>
                                        <p>The funny prank reels are a blast! I love using them to prank my family and friends. The app is super easy to use, and the reactions are priceless. Highly recommend for some good laughs!</p>
                                        <div className='review-user'>
                                            <img src="/web/review/user2.png" alt="Ronaldo Jones - Voice Changer App Reviewer" />
                                            <div className="review-user-details">
                                                <h4>Rolando Jones</h4>
                                                <span>March 10, 2020</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='review-content'>
                                        <div className='review-star'>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star"></i>
                                        </div>
                                        <p>"Pranking my friends has never been more fun with all the funny sounds available. The variety is fantastic, and I can always find the perfect sound for any situation. This app is a must-have for prank lovers!"</p>
                                        <div className='review-user'>
                                            <img src="/web/review/user3.png" alt="Jacob Rayan - Voice Changers App Reviewer" />
                                            <div className="review-user-details">
                                                <h4>Jacob Ryan</h4>
                                                <span>April 25, 2020</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='w-download'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={7}>
                            <div className='download-content'>
                                <h2>Download Our App Now!</h2>
                                <p>Download now to experience the laughter with AI Voice Changer, where you can sound like a celebrity, prank friends, create hilarious voiceovers, and share the fun!</p>
                                <div className='download-app-btn'>
                                    <a className="app-store-btn btn-google me-2" target="_blank" href="https://play.google.com/store/apps/details?id=com.voice.changer.sound.effects.girl.call" role="button">
                                        <span className="app-store-btn-subtitle">Get It On</span>
                                        <span className="app-store-btn-title">Google Play</span>
                                    </a>
                                    <a className="app-store-btn btn-apple ms-2" target="_blank" href="https://apps.apple.com/my/app/ai-voice-changer-sound-board/id1659583821" role="button">
                                        <span className="app-store-btn-subtitle">Download on the</span>
                                        <span className="app-store-btn-title">App Store</span>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className='download-media'>
                                <img src="/web/review/download.png" className='img-fluid' alt="Download Our App Now!" title='Download Our App Now!' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <footer className='w-footer'>
                <Container>
                    <Row>
                        <Col xs={12} className='text-center'>
                            <div className='footer-logo'>
                                <img src="/web/logo/logo.png" alt="AI Voice Changer: Funny Effect" title='AI Voice Changer: Funny Effect'/>
                                <span>AI Voice Changer: Funny Effect</span>
                            </div>
                            <div className='footer-menu'>
                                <ul>
                                    <li>
                                        <a href="#home">Home</a>
                                    </li>
                                    <li>
                                        <a href="#about">About</a>
                                    </li>
                                    <li>
                                        <a href="#features">Features</a>
                                    </li>
                                    <li>
                                        <a href="#howitworks">How It Works</a>
                                    </li>
                                    <li>
                                        <a href="#screens">Screens</a>
                                    </li>
                                    <li>
                                        <a href="#reviews">Reviews</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copy-right">
                                <p>© {currentYear} AI Voice Changer: Funny Effect. All Rights Reserved</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
            {showButton && (
                <button id="back-to-top" onClick={scrollToTop}>
                    <i className="bx bxs-up-arrow-alt"></i>
                </button>
            )}
        </div>
    )
}

export default WelCome
