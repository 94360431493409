import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { SelectPicker } from "rsuite";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { CelbrityVoiceview, CelbrityVoiceviewDropdown, CelebrityVoiceDelete } from '../../Auth/Api';
import $ from "jquery";

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const CelebrityVoiceView = () => {
    const Redirect = useNavigate()
    const [Data, SetData] = useState([])
    const [loading, Setloading] = useState(true)
    const [ViewSearchData, SetViewSearchData] = useState([])
    const params = useParams()
    const [mainId, setmainId] = useState(params.id)
    const [voiceDropdown, setVoiceDropdown] = useState([])

    const GetViewData = async (ids) => {
        const Result = await CelbrityVoiceview(mainId)
        if (Result.data.Status === true) {
            SetData(Result.data.Data)
            Setloading(false)
        }
        else {
            SetData([])
            Setloading(false)
        }
    }
    const GetAllDropdownData = async () => {
        const Result = await CelbrityVoiceviewDropdown()
        if (Result.data.Status === true) {
            setVoiceDropdown(Result.data.Data.map((val) => {
                return {
                    label: val.name,
                    value: val._id
                }
            }))
        }
        else {
            setVoiceDropdown([])
        }
    }
    const handleOnchange = (e) => {
        setmainId(e)
        Redirect(`/Celebrity-Voice/View/${e}`)
    }
    useEffect(() => {
        GetViewData("")
        GetAllDropdownData()
    }, [mainId])

    let count = 10;
    let swalCountdownInterval;
    const DeleteVoice = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: "../../icon/alert.svg",
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr("disabled", true);
                    swalCountdownInterval = setInterval(function () {
                        count--;
                        if (count < 1) {
                            $(".counter").text(`OK`);
                            $(".swal2-confirm").attr("disabled", false);
                            clearInterval(swalCountdownInterval);
                        } else {
                            $(".counter").text(`OK (${count})`);
                        }
                    }, 1000);
                },
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const Result = await CelebrityVoiceDelete(mainId || id);
                    if (Result.data.Status === true) {
                        toast.success("Voice Delete Successfully...");
                        Redirect(`/Celebrity-Voice`)
                    } else {
                        toast.error(Result.data.Response_Message);
                    }
                } else {
                    count = 10;
                    clearInterval(swalCountdownInterval);
                }
            });
    };

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3><Link to="/Celebrity-Voice" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Celebrity Voice View</h3>
                <div className="page-heading-right">
                    <SelectPicker data={voiceDropdown} cleanable={false} onChange={(e) => handleOnchange(e)} value={mainId} className="wv-200 my-1 ms-3" placeholder="Select Title" placement="bottomEnd" />
                    <Link to={`/Celebrity-Voice/Edit/${mainId}`}>
                        <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
                    </Link>
                    <Button variant="danger ms-3 my-1 btn-icon-lg" onClick={() => DeleteVoice(mainId)} type="button"><i className="bx bx-trash-alt"></i></Button>
                </div>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                        <Row>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Celebrity Name</p>
                                    <span>{Data.name}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Thumb Image</p>
                                    <span>
                                        {Data.thumb_image_url !== "" ?
                                            <Fancybox>
                                                <a href={Data.thumb_image_url} data-fancybox="gallery">
                                                    <img src={Data.thumb_image_url} alt="" className="hv-40 rounded-3" />
                                                </a>
                                            </Fancybox> : "-"
                                        }
                                    </span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Child Image</p>
                                    <span>
                                        {Data.child_image !== "" ?
                                            <Fancybox>
                                                <a href={Data.child_image} data-fancybox="gallery">
                                                    <img src={Data.child_image} alt="" className="hv-40 rounded-3" />
                                                </a>
                                            </Fancybox> : "-"
                                        }
                                    </span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Premium Status</p>
                                    <span>{Data.premium_status == 1 ? "Yes" : "No"}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Reward Status</p>
                                    <span>{Data.reward_status == 1 ? "Yes" : "No"}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p>
                                    <span>{Data.status === 0 || Data.status == null ? "Off" : "On"}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default CelebrityVoiceView