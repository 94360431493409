import React from 'react';
import { Nav } from 'react-bootstrap';

function NavigationMenu({ activeSection }) {
  return (
    <>
    <Nav className="ms-auto align-items-center" navbarScroll>
        <Nav.Link className={activeSection === 'home' ? 'active' : ''} href="#home">Home</Nav.Link>
        <Nav.Link className={activeSection === 'about' ? 'active' : ''} href="#about">About</Nav.Link>
        <Nav.Link className={activeSection === 'features' ? 'active' : ''} href="#features">Features</Nav.Link>
        <Nav.Link className={activeSection === 'howitworks' ? 'active' : ''} href="#howitworks">How It Works</Nav.Link>
        <Nav.Link className={activeSection === 'screens' ? 'active' : ''} href="#screens">Screens</Nav.Link>
        <Nav.Link className={activeSection === 'reviews' ? 'active' : ''} href="#reviews">Reviews</Nav.Link>
    </Nav>
    </>
  );
}

export default NavigationMenu;
